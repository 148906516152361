header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 13px;
    clear: both;

    width: 100vw;
    min-width: 800px;
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
    float: left;
    flex-shrink: 0;
}

header span,
header a {
    text-decoration: none;
    color: #fff;
    padding: 1.15em 1em;
}

.header_row_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 47px;
    background-color: #369;
}

#company_name {
    display: flex;
    flex-direction: row;
    background-color: #005295;
    align-items: center;
}

#suite_name {
    padding: 0 .5em 0 1em;
    font-weight: 700;
}

.header_row_2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 47px;
    align-items: center;
    background-color: #eaeaea;
    color: black;
}

.header_row_2 span {
    color: black;
    padding: 0
}

#app_name {
    font-weight: 700;
    margin: 0 0 0 1em;
    padding: 0;
    font-size: 24px;
    color: black;
    padding-right: 0;
}

#app_description {
    font-size: 16px;
    margin-left: 0;
    padding-bottom: 5px;
    align-self: flex-end;
}