footer {
    position: sticky;
    top: calc(100vh - 120px);
    height: 120px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-start;
    width: 100%;
    font-size: 13px;
    padding: 0 1em;
    border-top: 2px solid #D5D5D5;
    clear: both;
    overflow: hidden;
    background-color: #EAEAEA;
    flex-shrink: 0;
}

footer a {
    text-decoration: none;
    margin: 0 1em;
}

.address {
    margin: 0 1em 1em 1em;
}

.footer_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 1em;
}

.logo {
    opacity: .5;
    height: 50px;
}

